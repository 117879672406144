import {
  useAddress,
  useMetamask,
  useNetworkMismatch,
  ChainId,
  useNetwork,
  useEditionDrop,
  useNFTBalance,
  useClaimNFT,
} from "@thirdweb-dev/react";
import "./styles.css";

// truncates the address so it displays in a nice format
function truncateAddress(address) {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

export default function App() {
  // allow user to connect to app with metamask, and obtain address
  const address = useAddress();
  const connectWithMetamask = useMetamask();
  const networkMismatched = useNetworkMismatch();
  const [, switchNetwork] = useNetwork(); // Switch network

  // Replace this address with your NFT Drop address!
  const editionDrop = useEditionDrop(
    "0xdEa7aD6B1EA752bCC4041eD547cD6462ae51c8c0"
  );
  const claimNFT = useClaimNFT(editionDrop);

  const { data: balance, isLoading } = useNFTBalance(editionDrop, address, "0");

  const mintNft = () => {
    // Ensure they're on the right network (mumbai)
    if (networkMismatched) {
      switchNetwork(ChainId.Polygon);
      return;
    }

    claimNFT.mutate(
      { to: address, quantity: 1, tokenId: 0 },
      {
        onSuccess: () => {
          console.log("Successfully minted!");
        },
        onError: (err) => {
          console.error("Failed to mint NFT", err);
        },
      },
    );
  };

  //if there isn't a wallet connected, display our connect MetaMask button
  if (!address) {
    return (
      <div className="container">
        <h1>Welcome to Wizards Finance</h1>
        <button className="btn" onClick={connectWithMetamask}>
          Connect MetaMask
        </button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="container">
        <h1>Checking your wallet...</h1>
      </div>
    );
  }

  // if the user is connected and has an NFT from the drop, display text
  if (balance > 0) {
    return (
      <div>
        <h2>Congratulations! You have a WizardsFinance NFT!</h2>

        <div class="row">
  <div class="column">
    <div class="card">

      <div class="container">
        <h2>DeFi</h2>
        <p class="title">100mb</p>
        <p>COURSE CONTENTS HERE</p>
        <p>20 pages</p>
        <a href="DeFi.pdf" download>
        <p><button class="btn"><i class="fa fa-download"></i> Download</button></p> 
        </a>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="card">
      <div class="container">
        <h2>Economics</h2>
        <p class="title">100mb</p>
        <p>COURSE CONTENTS HERE</p>
        <p>20 pages</p>
        <a href="DeFi.pdf" download>
        <p><button class="btn"><i class="fa fa-download"></i> Download</button></p> 
        </a> 
      </div>
    </div>
  </div>
  
  <div class="column">
    <div class="card"> 
      <div class="container">
        <h2>Crypto</h2>
        <p class="title">100mb</p>
        <p>COURSE CONTENTS HERE</p>
        <p>20 pages</p>
        <a href="DeFi.pdf" download>
        <p><button class="btn"><i class="fa fa-download"></i> Download</button></p> 
        </a>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="column">
    <div class="card">

      <div class="container">
        <h2>Bitcoin</h2>
        <p class="title">100mb</p>
        <p>COURSE CONTENTS HERE</p>
        <p>20 pages</p>
        <a href="DeFi.pdf" download>
        <p><button class="btn"><i class="fa fa-download"></i> Download</button></p> 
        </a>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="card"> 
      <div class="container">
        <h2>Stocks</h2>
        <p class="title">100mb</p>
        <p>COURSE CONTENTS HERE</p>
        <p>20 pages</p>
        <a href="DeFi.pdf" download>
        <p><button class="btn"><i class="fa fa-download"></i> Download</button></p> 
        </a>
      </div>
    </div>
  </div>
  
  <div class="column">
    <div class="card"> 
      <div class="container">
        <h2>TA</h2>
        <p class="title">100mb</p>
        <p>COURSE CONTENTS HERE</p>
        <p>20 pages</p>
        <a href="DeFi.pdf" download>
        <p><button class="btn"><i class="fa fa-download"></i> Download</button></p> 
        </a>
      </div>
    </div>
  </div>
</div>

      </div>
    );
  }

  // if there are no NFTs from collection in wallet, display button to mint
  return (
    <div className="container">
      <p className="address">
        There are no Wizards Finance NFTs held by:{" "}
        <span className="value">{truncateAddress(address)}</span>
      </p>
      <button className="btn" disabled={claimNFT.isLoading} onClick={mintNft}>
        {claimNFT.isLoading ? "Minting..." : "Mint NFT"}
      </button>
    </div>
  );
}
