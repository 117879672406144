import React from "react";

export default function GitHubLink() {
  const url = "https://www.wizardsfinances.com ";
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: -120,
          right: -80,
          height: 300,
          width: 150,
          border: "linear-gradient(to bottom, #0000cc 0%, #ff00ff 100%);",
          transform: "rotate(45deg)",
          backgroundColor: " linear-gradient(to bottom, #0000cc 0%, #ff00ff 100%);",
          cursor: "pointer",
        }}
        role="button"
        onClick={() => window.open(url, "_blank")}
      />

      <div
        style={{
          position: "fixed",
          bottom: 14,
          right: 18,
        }}
      >
        <img
          alt=""
          src={"favicon.ico"}
          width={40}
          height={40}
          role="button"
          style={{ cursor: "pointer" }}
          onClick={() => window.open(url, "_blank")}
        />
      </div>
    </>
  );
}
